import React from 'react'
import Footer from './Footer';
import MobileMenu from './MobileMenu';
import Header from './Header';
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';

const Layout = ({ children }) => {

    return (
        <div>
            <MobileMenu />

            <div className='row justify-content-center site_width_mobile'>
                <div className='col-lg-11 col-xl-11 p-0 ps-lg-3 pe-lg-3 site_width'>
                  
                    <Header/>
                    
                    {children}

                    <Footer />
                </div>
            </div>
            {/* <div className=''>
            <CookieConsent buttonText="Allow Cookie"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#ffffff", fontSize: "13px", backgroundColor: '#000000', border: '1px solid #f1f1f1', marginRight: '10rem' }}><span className='p-lg-5 ms-lg-4'>Your experience on this site will be improved by allowing cookies <Link to={'/cookie-policy'} >Cookie Policy</Link></span>
                </CookieConsent>
            </div> */}
        </div>
    )
}
export default React.memo(Layout);